<template>
	
	<div class="grid">
		<Toast />
		<template v-if="ingresoCostumer==false">
			<div class="col-12 lg:col-12">
			<center><h2>Orígenes y Destinos</h2></center>
			</div>
			<template v-for="(customer,index) in customers" :key="index">
				<div class="col-12 lg:col-6">
					<div class="card height-90 flex flex-column m-3">
						<div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
							<img src="layout/images/widgets/feature-onboarding.svg" alt="feature-faq">
						</div>
						<div class="flex flex-column align-items-center mt-3">
							<h5 class="mb-2">{{customer.customer.name}}</h5>
						</div>
						<div class="flex justify-content-between align-items-center pt-3 mt-auto">
							<small class="muted-text"></small>
							<template v-if="customer.status==1">
								<Button label="Ingresar" @click="abrirCustomer(customer,index)"></Button>
							</template>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<div class="col-12 lg:col-12">
				<!-- <center><h2 class="alignment-center">{{ infoCostumer.customer.name }}</h2></center> -->
			</div>
			<div class="col-12 lg:col-12">
			<Toolbar class="mb-4">
				<template v-slot:start>
					<div class="my-2">
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						<!-- <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" /> -->
					</div>
					<div class="mt-3 ml-5">
						<center><h3>Orígenes y Destinos</h3></center>
						<!-- <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" /> -->
					</div>
				</template>
				<!-- <template v-slot:end>
					<Button label="Volver" icon="pi pi-chevron-left" class="mr-2 mb-2" @click="salirCustomer"></Button><br>
				</template> -->
			</Toolbar>
			<template v-if="loader==true">
				<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
			</template>
			<DataTable ref="dt" :value="origenes_destinos" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customers" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<!-- <center><h5 class="m-0">Centros de costos</h5></center> -->
							<!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                            </span> -->
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Nombre</span>
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="ciudad" header="Ciudad" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Ciudad</span>
							{{slotProps.data.nombre_division}}
						</template>
					</Column>
					<Column field="direccion" header="Dirección" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Dirección</span>
							{{slotProps.data.direccion}}
						</template>
					</Column>
					<Column field="telefono" header="Telefono" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Telefono</span>
							{{slotProps.data.telefono}}
						</template>
					</Column>
					<Column field="origen" header="Origen" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Origen</span>
							<template v-if="slotProps.data.origen==1">
								{{'Si'}}
							</template>
							<template v-else>
								{{'No'}}
							</template>
						</template>
					</Column>
					<Column field="destino" header="Destino" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Destino</span>
							<template v-if="slotProps.data.destino==1">
								{{'Si'}}
							</template>
							<template v-else>
								{{'No'}}
							</template>
						</template>
					</Column>
					<Column field="acciones" header="Acciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<div>
					<Paginator :v-model:first="pagination.from" :rows="pagination.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
				</div>

				<Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="Orígenes y Destinos" :modal="true" class="p-fluid">
					<!-- <img :src="'demo/images/product/' + product.image" :alt="product.image" v-if="product.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" /> -->
					<div class="field">
						<label for="name">Nombre</label>
						<InputText id="nombre" v-model.trim="frmOrigenes.nombre" required="true" autofocus :class="{'p-invalid': submitted && !frmOrigenes.nombre}" />
						<small class="p-invalid p-error" v-if="!frmOrigenes.nombre">Nombre es requerido.</small>
					</div>
					<div class="field">
						<label for="ciudad">Ciudad</label><br>
						<!-- <InputText id="ciudad" v-model="frmOrigenes.ciudad" required="true" autofocus :class="{'p-invalid': submitted && !frmOrigenes.ciudad}" /> -->
						<!-- <MultiSelect v-model="seleczona" :options="zonas" :filter="true" :filterFields="['nombre_division']" optionLabel="nombre_division" placeholder="Seleccionar Ciudad toArray" :selectionLimit="1" :showToggleAll="false"/> -->
						<!-- <MultiSelect v-model="seleczona" :options="zonas1" :filter="true" optionLabel="nombre_division" placeholder="Seleccionar Ciudad json_encode" :selectionLimit="1" :showToggleAll="false"/> -->
						<!-- <MultiSelect v-model="seleczona" :options="zonas2" :filter="true" filterMatchMode="contains" optionValue="id" optionLabel="nombre_division" placeholder="Seleccionar Ciudad .json del front" :selectionLimit="1" :showToggleAll="false"/> -->
						<Multiselect v-model="frmOrigenes.id_ciudad" :max="-1" valueProp="id" :options="zonas" :custom-label="nombrezonas" :searchable="true" label="nombre_division" track-by="nombre_division"  :class="{'p-invalid': submitted && !frmOrigenes.id_ciudad}"/>
						<small class="p-invalid p-error" v-if="!frmOrigenes.id_ciudad">Ciudad es requerido.</small>
					</div>
					<div class="field">
						<label for="direccion">Dirección</label>
						<InputText id="direccion" v-model.trim="frmOrigenes.direccion" required="true" autofocus :class="{'p-invalid': submitted && !frmOrigenes.direccion}" />
						<small class="p-invalid p-error" v-if="!frmOrigenes.direccion">Dirección es requerido.</small>
					</div>
					<div class="field">
						<label for="telefono">Telefono</label><br>
						<InputText id="telefono" v-model="frmOrigenes.telefono" type="number" required="true" autofocus />
					</div>

					<div class="field"> 
						<label for="inventoryStatus" class="mb-3">Seleccionar empresa</label>
						<Dropdown v-model="selectgenerador" :options="generadores" optionLabel="razon_social" placeholder="Seleccionar una empresa" />
						<small class="p-invalid p-error" v-if="!selectgenerador">Selección de empresa es requerido.</small>
						
					</div>

					<div class="field">
						<label class="mb-3">Origen y Destino</label>
						<div class="formgrid grid">
							<div class="field-radiobutton col-6">
								<InputSwitch v-model="frmOrigenes.origen" />
								<label for="category1">Origen ({{sionorigen()}})</label>
							</div>
							<div class="field-radiobutton col-6">
								<InputSwitch v-model="frmOrigenes.destino" />
								<label for="category1">Destino ({{sionodestino()}})</label>
							</div>
						</div>
						
					</div>
					<div class="field">
						<label for="description">Descripción (Indicaciones Adicionales y/o complementarias)</label><br>
						<Textarea id="description" v-model="frmOrigenes.descripcion" required="true" rows="3" cols="53" autofocus />
					</div>
					<div class="field">
						<label for="description">Descripción del horario (Horario de Atención o Disponibilidad)</label><br>
						<Textarea id="description" v-model="frmOrigenes.descripcion_horario" required="true" rows="3" cols="53" autofocus />
					</div>
					
					<template #footer>
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></center>
						</template>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<template v-if="!frmOrigenes.id_origenes">
						<Button id="buttoncrear" label="Crear" icon="pi pi-check" class="p-button-text" @click="crearorigenesdestinos" />
						</template>
						<template v-else>
							<Button id="buttoneditar" label="Editar" icon="pi pi-check" class="p-button-text" @click="editarorigenesdestinos" />
						</template>
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro de que quieres eliminar este registro <b></b>?</span><br>
						<!-- <template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template> -->
					</div>
					<div class="flex align-items-center justify-content-center">
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button id="buttoneliminar" label="Eliminar" icon="pi pi-check" class="p-button-text" @click="deleteOrigenes" />
					</template>
				</Dialog>
			</div>
		</template>
	
	</div>
</template>

<script>
// import axios from 'axios'
import {FilterMatchMode} from 'primevue/api';
export default {
	data() {
		return {
			message: [],
			customers: [],
			username:null,
			email:null,
			count: 0,
			display: false,
			loader: false,
			frmOrigenes: {
				id_origenes: '',
				nombre: '',
				id_ciudad: '',
				direccion: '',
				id_customer: '',
				id_generador: '',
				telefono: '',
				origen: '',
				destino: '',
				descripcion: '',
				descripcion_horario: ''
			},
			selectgenerador: null,
			generadores: [],
			ingresoCostumer: false,
			infoCostumer: [],
			zonas: [],
			origenes_destinos: [],
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			seleczona: [],
			seleczona1: [],
			pagination: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			// zonas2: [],
			filters: {},
			value: null,
			options: [
			'Batman',
			'Robin',
			'Joker',
			],
			submitted: false
		}
	},
	components:{
        // Multiselect
		// Multiselect
		
    },
	mounted(){
		this.abrirCustomer();
	},
	methods:{
		abrirCustomer() {
			const cookieName = 'IdCustomer';
			const cookieUserRol = 'IdUsuarioRol';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			});
			var id_customer = cookie[cookieName];
			var id_user_rol = cookie[cookieUserRol];
			
			this.loadDataTable1(1, id_customer);
			this.loadData(id_user_rol);
			this.loadZonas();
			this.ingresoCostumer = true;
		},
		onChangePage(page) {
			console.log(page);
			console.log(page.page+1);
				const cookieName = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				
				const id_customer = cookie[cookieName];
				console.log(id_customer);
				this.loadDataTable1((page.page+1),id_customer);
		},
		clearPaginator(){
			this.pagination.current_page = 0;
			this.pagination.from = 0;
			this.pagination.last_page = 0;
			this.pagination.per_page = 0;
			this.pagination.to = 0;
			this.pagination.total = 0;
		},
		nombrezonas({nombre_division}){
            return nombre_division;
        },
		mostrardatoszonas(){
			console.log(this.seleczona);
		},
		loadZonas(){
			this.zonas = [];
			this.loader = true;
			var _that = this;
			// axios.get('/cargar_zonas').then(response => {
			// 	_that.zonas = response.data.data;
			// 	// this.zonas1 = response.data.data1;
				
			// 	console.log(_that.zonas);
			// 	_that.loader = false;
			// }).catch(function (error) {
			// 	console.log(error);
			// 	_that.loader = false;
			// });

			var url = '/cargar_zonas';
			window.api.call('get', url).then( function(response) {
				if(response.status == 200){
					_that.zonas = response.data.data;
				}
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
		nombregenerador({generador}){
            return generador.razon_social;
        },
		sionorigen(){
			if(this.frmOrigenes.origen==true){
				return 'Si'
			}else{
				return 'No'
			}
		},
		sionodestino(){
			if(this.frmOrigenes.destino==true){
				return 'Si'
			}else{
				return 'No'
			}
		},
		crearorigenesdestinos(){
			var _that = this;
			this.loader = true;
			this.submitted = false;
			console.log(this.seleczona);
			// console.log(this.frmOrigenes);
			if(this.frmOrigenes.origen==true){
				this.frmOrigenes.origen = 1;
			}else{
				this.frmOrigenes.origen = 0;
			}
			if(this.frmOrigenes.destino==true){
				this.frmOrigenes.destino = 1;
			}else{
				this.frmOrigenes.destino = 0;
			}
			if(this.frmOrigenes.nombre=='' || !this.frmOrigenes.direccion || this.selectgenerador==null || !this.frmOrigenes.id_ciudad){
				this.loader = false;
				this.submitted = true;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor ingresar todos los campos', life: 4000});
			}else{
				var buttoncrear = document.getElementById('buttoncrear');
				buttoncrear.setAttribute("disabled", "");
				// var cook =  document.cookie.split(";");
				// const id_customer = cook[4].replace(' IdCustomer=', '');
				// const id_customer = localStorage.getItem( 'IdCustomer');
				const cookieName = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				const id_customer = cookie[cookieName];
				this.frmOrigenes.id_generador = this.selectgenerador.id;
				this.frmOrigenes.id_customer = id_customer;

				console.log(this.frmOrigenes);
			
				// axios.post('/crear_origenes_destinos', {frmOrigenes: this.frmOrigenes}).then(response => {
				// 	console.log(response.data.data);
				// 	// _that.loadDataTable(_that.frmOrigenes.id_customer);
				// 	_that.clearPaginator();
				// 	_that.loadDataTable1(1, _that.frmOrigenes.id_customer);
				// 	_that.hideDialog();
				// 	_that.$toast.add({severity:'success', summary: 'Creación exitosa', detail: response.data.message, life: 4000});
				// 	// _that.loader = false;
				// }).catch(function (error) {
                //     if (error.response) {
				// 		_that.loader = false;
				// 		console.log(error.response);
				// 		_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 4000});
				// 		buttoncrear.removeAttribute('disabled');
				// 	}
				// });

				var url = '/crear_origenes_destinos';
                window.api.call('post', url, {frmOrigenes: this.frmOrigenes}).then( function(response) {
                    if(response.status == 200){
                       console.log(response.data.data);
							_that.clearPaginator();
							_that.loadDataTable1(1, _that.frmOrigenes.id_customer);
							_that.hideDialog();
							_that.$toast.add({severity:'success', summary: 'Creación exitosa', detail: response.data.message, life: 4000});
                    }
                }).catch(function (error) {
                    if (error.response) {
						_that.loader = false;
						console.log(error.response);
						_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 4000});
						buttoncrear.removeAttribute('disabled');
					}
				});
			}
		},
		editarorigenesdestinos(){
			var _that = this;
			this.loader = true;
			// console.log(this.seleczona);
			// console.log(this.seleczona[0]);
			if(this.frmOrigenes.origen==true){
				this.frmOrigenes.origen = 1;
			}else{
				this.frmOrigenes.origen = 0;
			}
			if(this.frmOrigenes.destino==true){
				this.frmOrigenes.destino = 1;
			}else{
				this.frmOrigenes.destino = 0;
			}
			if(this.frmOrigenes.nombre=='' || !this.frmOrigenes.direccion || this.selectgenerador==null || !this.frmOrigenes.id_ciudad){
				this.loader = false;
				this.submitted = true;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor ingresar todos los campos', life: 4000});
			}else{
				const cookieName = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				const id_customer = cookie[cookieName];
				this.frmOrigenes.id_generador = this.selectgenerador.id;
				this.frmOrigenes.id_customer = id_customer;
				var buttoneditar1 = document.getElementById('buttoneditar');
				buttoneditar1.setAttribute("disabled", "");
				// axios.post('/editar_origenes_destinos', {frmOrigenes: this.frmOrigenes}).then(response => {
				// 	console.log(response.data.data);
				// 	// _that.loadDataTable(_that.frmOrigenes.id_customer);
				// 	_that.clearPaginator();
				// 	_that.loadDataTable1(1, _that.frmOrigenes.id_customer);
				// 	_that.hideDialog();
				// 	_that.$toast.add({severity:'success', summary: 'Edición exitosa', life: 4000});
				// 	// _that.loader = false;
				// 	buttoneditar1.removeAttribute('disabled');
				// }).catch(function (error) {
                //     if (error.response) {
				// 		buttoneditar1.removeAttribute('disabled');
				// 		_that.loader = false;
				// 		console.log(error.response);
				// 		_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 4000});
				// 	}
				// });
				

				var url = '/editar_origenes_destinos';
                window.api.call('post', url, {frmOrigenes: this.frmOrigenes}).then( function(response) {
                    if(response.status == 200){
						console.log(response.data.data);
						_that.clearPaginator();
						_that.loadDataTable1(1, _that.frmOrigenes.id_customer);
						_that.hideDialog();
						_that.$toast.add({severity:'success', summary: 'Edición exitosa', life: 4000});
						buttoneditar1.removeAttribute('disabled');
                    }
                }).catch(function (error) {
                    if (error.response) {
						buttoneditar1.removeAttribute('disabled');
						_that.loader = false;
						console.log(error.response);
						_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 4000});
					}
				});
			}
		},
		deleteOrigenes(){
			var _that = this;
			var buttoneliminar = document.getElementById('buttoneliminar');
			buttoneliminar.setAttribute("disabled", "");
			this.loader = true;
			const cookieName = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				const id_customer = cookie[cookieName];
			this.frmOrigenes.id_customer = id_customer;
			// axios.delete('eliminar_origenes_destinos/' + this.frmOrigenes.id_origenes)
			// .then(response => {
			// 	// this.result.splice(id, 1)
			// 	// _that.loadDataTable(_that.frmOrigenes.id_customer);
			// 	_that.clearPaginator();
			// 	_that.loadDataTable1(1, _that.frmOrigenes.id_customer);
			// 	console.log(response);
			// 	// _that.loader = false;
			// 	_that.clearfrmOrigenes();
			// 	_that.deleteProductDialog=false;
			// 	_that.$toast.add({severity:'success', summary: 'Eliminación exitosa', life: 4000});
			// }).catch(error => {
			// 	console.log(error);
			// 	buttoneliminar.removeAttribute('disabled');

			// 	_that.loader = false;
			// 	// _that.loadDataTable(_that.frmOrigenes.id_customer);
			// 	_that.clearPaginator();
			// 	_that.loadDataTable1(1, _that.frmOrigenes.id_customer);
			// 	_that.clearfrmOrigenes();
			// });

			var url = 'eliminar_origenes_destinos/' + this.frmOrigenes.id_origenes;
			window.api.call('delete', url).then( function(response) {
				if(response.status == 200){
					_that.clearPaginator();
					_that.loadDataTable1(1, _that.frmOrigenes.id_customer);
					console.log(response);
					_that.clearfrmOrigenes();
					_that.deleteProductDialog=false;
					_that.$toast.add({severity:'success', summary: 'Eliminación exitosa', life: 4000});
				}
			}).catch(error => {
				console.log(error);
				buttoneliminar.removeAttribute('disabled');
				_that.loader = false;
				_that.clearPaginator();
				_that.loadDataTable1(1, _that.frmOrigenes.id_customer);
				_that.clearfrmOrigenes();
			});
		},
		loadData(id_user_rol){
			this.generadores = [];
			// axios.post('/cargar_generadores', {usuario_rol: id_user_rol}).then(response => {
			// 	this.generadores = response.data.data;
			// 	console.log(response.data.data);

			// }).catch(function (error) {
			// 	console.log(error);
			// });
			var url = '/cargar_generadores';
			this.loader = true;
			var _that = this;
			window.api.call('post', url, {usuario_rol: id_user_rol}).then( function(response) {
				if(response.status == 200){
					// _that.generadores = response.data.data;
					_that.generadores = response.data.generadores;
					console.log(response.data.data);
				}
			}).catch(function (err) {
				console.log(err);
			});
		},
		loadDataTable1(page, id_customer){
			this.origenes_destinos = [];
			this.loader = true;
			var _that = this;
			// axios.post('/cargar_origenes_destinos_table?&page='+page, {id_customer: id_customer}).then(response => {
			// 	_that.origenes_destinos = response.data.data.data;
			// 	_that.pagination = response.data.data;
			// 	console.log(response.data.data);
			// 	_that.loader = false;
			// }).catch(function (error) {
			// 	console.log(error);
			// 	_that.loader = false;
			// });
			var url = '/cargar_origenes_destinos_table?&page='+page;
			window.api.call('post', url, {id_customer: id_customer}).then( function(response) {
				if(response.status == 200){
					_that.origenes_destinos = response.data.data.data;
					_that.pagination = response.data.data;
					console.log(response.data.data);
					_that.loader = false;
				}
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		clearfrmOrigenes(){
			this.frmOrigenes.nombre = '';
			this.frmOrigenes.id_origenes = '';
			this.frmOrigenes.ciudad = '';
			this.frmOrigenes.telefono = '';
			this.frmOrigenes.descripcion = '';
			this.frmOrigenes.direccion = '';
			this.frmOrigenes.descripcion_horario = '';
			this.frmOrigenes.id_customer = '';
			this.frmOrigenes.id_generador = '';
			this.selectgenerador = null;
			this.seleczona = null;
			this.frmOrigenes.origen = false;
			this.frmOrigenes.destino = false;
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
			this.clearfrmOrigenes();
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		editProduct(product) {
			// this.seleczona[0] = '';
			console.log(product);
			this.frmOrigenes.nombre = product.nombre;
			this.frmOrigenes.id_origenes = product.id;
			this.frmOrigenes.direccion = product.direccion;
			this.frmOrigenes.id_customer = product.id_customer;
			this.frmOrigenes.id_generador = product.id_generador;
			this.frmOrigenes.id_ciudad = product.id_ciudad;
			this.frmOrigenes.telefono = product.telefono;
			if(product.origen==1){
				this.frmOrigenes.origen = true;
			}else{
				this.frmOrigenes.origen = false;
			}
			if(product.destino==1){
				this.frmOrigenes.destino = true;
			}else{
				this.frmOrigenes.origen = false;
			}
			// this.frmOrigenes.origen = product.origen;
			// this.frmOrigenes.destino = product.destino;
			this.frmOrigenes.descripcion = product.descripcion;
			this.frmOrigenes.descripcion_horario = product.descripcion_horario;

			var gene = this.generadores.find((usuario) => usuario.id == product.id_generador);
            this.selectgenerador = gene;

			// var lugarzona = this.zonas.find((zona) => zona.id == product.id_ciudad);
            // this.seleczona[0] = lugarzona;
			// this.seleczona = product.ciudad;
			// this.seleczona = this.seleczona.push(lugarzona);
			// console.log(lugarzona);
			// this.seleczona = lugarzona;
			// console.log(this.seleczona);

			this.product = {...product};
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			// this.product = product;
			this.frmOrigenes.nombre = product.nombre;
			this.frmOrigenes.id_origenes = product.id;
			this.frmOrigenes.direccion = product.direccion;
			this.frmOrigenes.id_customer = product.id_customer;
			this.frmOrigenes.id_generador = product.id_generador;
			this.frmOrigenes.id_ciudad = product.id_ciudad;
			this.frmOrigenes.telefono = product.telefono;
			this.deleteProductDialog = true;
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
// @import '../assets/demo/styles/badges.scss';
</style>
